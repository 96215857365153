import React from 'react'

const List = ({children, ...listProps}) => (
	<ul {...listProps}>
		{React.Children.map(
			children,
			child => child && <li key={child.props.key}>{child}</li>
		)}
	</ul>
)

export default List
