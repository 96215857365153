import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql, Link} from 'gatsby'
import Helmet from 'react-helmet'
import Metadata from './metadata'
import favicon from '../_img/favicon.ico'

import './index.scss'

const Layout = ({children, location}) => (
	<StaticQuery
		query={graphql`
			query {
				site {
					m: siteMetadata {
						title
						description
						keywords
						themeColor
					}
				}
			}
		`}
		render={data => (
			<React.Fragment>
				<Helmet>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta name="theme-color" content={data.site.m.themeColor} />
					<link rel="shortcut icon" type="image/png" href={favicon} />
				</Helmet>
				<Metadata
					name={data.site.m.title}
					title={data.site.m.title}
					keywords={data.site.m.keywords.join(`, `)}
					description={data.site.m.description}
					url={`${data.site.m.siteUrl}${location.pathname}`}
				/>
				<header>
					<div className="header">
						<h1 className="main-heading">
							<Link to="/">{data.site.m.title}</Link>
						</h1>
						<h2 className="subheading">{data.site.m.description}</h2>
					</div>
					<hr className="main-hr" />
				</header>
				<main>{children}</main>
				<footer>
					<div className="footer-content">
						<p>
							Twitter:{' '}
							<a href="https://twitter.com/natalyathree">@natalyathree</a>
						</p>
						<p>
							Instagram:{' '}
							<a href="https://instagram.com/natalyathree">natalyathree</a>
						</p>
					</div>
				</footer>
			</React.Fragment>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.func,
}

export default Layout
