import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import List from './list'

const Nav = ({links, pathname}) => (
	<nav>
		<List>
			{links.previous ? (
				<Link to={`/${links.previous}`}>⟨ Older</Link>
			) : (
				<span className="link-placeholder">⟨ Older </span>
			)}
			{pathname !== `/first` && <Link to="/first">Start Here</Link>}
			{pathname !== `/` && <Link to="/">Newest Post</Link>}
			{links.next ? (
				<Link to={`/${links.next}`}>Newer ⟩</Link>
			) : (
				<span className="link-placeholder"> Newer ⟩ </span>
			)}
		</List>
	</nav>
)

Nav.propTypes = {
	links: PropTypes.shape({
		previous: PropTypes.string,
		next: PropTypes.string,
	}).isRequired,
	pathname: PropTypes.string.isRequired,
}

export default Nav
